import { useParams } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import styles from "../../styles/styles.module.css";
import ClassTucksLogo from "../../assets/class-trucks-small-logo.svg";
import Location from "../../assets/icons/location.svg";
import WhatsApp from "../../assets/icons/whatsapp.svg";
import TabContent from "./TabContent";
import OffersContainer from "../../components/Offers/OffersContainer";
import ContactModal from "./components/ContactModal";
function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
const Listing = (props) => {
  const params = useParams();

  const [listing, setListing] = useState(false);
  const [images, setImages] = useState([]);

  const [open, setOpen] = useState(false);

  useEffect(()=> {
    window.scrollTo(0, 0);

  },[])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/listing/${params.id}`)
      .then((res) => {
        setListing(res.data);
        const truck = res.data;
        if (truck.url_list) {
          const imgs = Object.entries(truck.url_list);
          const imgArr = [];
          for (const img of imgs) {
            imgArr.push({
              original: img[1],
              loading: "eager",
              thumbnail: img[1],
              thumbnailHeight: 72,
              thumbnailWidth: 108,
              thumbnailLoading: "lazy",
              originalWidth: 447,
              originalHeight: 318,
            });
          }
          setImages(imgArr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params.id]);
  return (
    <>
      <ContactModal listing={listing} open={open} setOpen={setOpen} />
      <div className={styles.listingWrapper}>
        {listing && (
          <>
            <div className={styles.mainDetails}>
              <div className={styles.title}>
                <h1>
                  {listing.brand} {listing.model}
                </h1>
                {listing.is_class_trucks && <img src={ClassTucksLogo} />}
              </div>
              <div className={styles.mainWrap}>
                <div className={styles.imageContainer}>
                  <ImageGallery
                    thumbnailPosition="left"
                    showThumbnails={true}
                    items={images}
                    showPlayButton={false}
                  />
                </div>

                <div className={styles.infoWrapper}>
                  <div className={styles.priceInfo}>
                    <p>{numberWithSpaces(listing.price)} €</p>
                    <button onClick={() => setOpen(!open)}>Send Request</button>
                  </div>
                  <div className={styles.priceInfoSecond}>
                    {listing?.cabin_name && (
                      <div className={styles.infoBit}>
                        <p className={styles.bitTitle}>Cabin:</p>
                        <p className={styles.bitElement}>
                          {listing.cabin_name}
                        </p>
                      </div>
                    )}

                    {listing?.first_registration && (
                      <div className={styles.infoBit}>
                        <p className={styles.bitTitle}>Registration Date:</p>
                        <p className={styles.bitElement}>
                          {listing.first_registration}
                        </p>
                      </div>
                    )}

                    {listing?.kw_power && (
                      <div className={styles.infoBit}>
                        <p className={styles.bitTitle}>Power, kW:</p>
                        <p className={styles.bitElement}>{listing.kw_power}</p>
                      </div>
                    )}

                    {listing?.mileage && (
                      <div className={styles.infoBit}>
                        <p className={styles.bitTitle}>Milage</p>
                        <p className={styles.bitElement}>
                          {numberWithSpaces(listing.mileage)} km
                        </p>
                      </div>
                    )}
                  </div>

                  <div className={styles.extraInfo}>
                    <div className={styles.location}>
                      <img src={Location} />
                      {listing.city}, {listing.country}
                    </div>
                  </div>
                  {listing.is_class_trucks && (
                    <div className={styles.contact}>
                      Contact: <a href="https://wa.me/37052142428" target="_blank"><img src={WhatsApp} /></a>
                    </div>
                  )}
                  {listing.author_email  && (
                    <div
                      className={styles.contact}
                      style={{ display: "block" }}
                    >
                      Contact:
                      <p
                        style={{
                          color: "black",
                          margin: "5px 0",
                          marginTop: "15px",
                        }}
                      >
                        {listing.author_name}
                      </p>
                      <p style={{ color: "black", margin: "5px 0" }}>
                        {listing.author_company}
                      </p>
                      <p style={{ color: "black", margin: "5px 0" }}>
                        {listing.author_email}
                      </p>
                      <p style={{ color: "black", margin: "5px 0" }}>
                        {listing.author_phone}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.technical}>
              <h3 className={`${styles.tabBut}`}>Technical Data</h3>
              <TabContent tab={"tech"} listing={listing} />
              <h3 className={`${styles.tabBut}`}>Description</h3>

              <TabContent tab={"desc"} listing={listing} />
              <h3 className={`${styles.tabBut}`}>Tires</h3>

              <TabContent tab={"tires"} listing={listing} />
              <h3 className={`${styles.tabBut}`}>Accessories</h3>
              <TabContent tab={"accessories"} listing={listing} />
            </div>
            <div className={styles.similarTab}>
              <h2 className={styles.h2}>Similar Vehicles</h2>
              <div className="similar-wrapper">
                <OffersContainer
                  limit={4}
                  visible={false}
                  category={listing.category}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Listing;
