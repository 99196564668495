import OfferCard from "./OfferCard";
import axios from "axios";
import { useState, useEffect } from "react";
import useWindowDimensions from "../../helpers/WindowDimensions";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";

const OffersContainer = (props) => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [visibleOffers, setVisibleOffers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0);
  const [countryFiler, setCountryFilter] = useState(
    props.visible
      ? "Lithuania"
      : searchParams.get("country")
      ? searchParams.get("country")
      : ""
  );
  const { height, width } = useWindowDimensions();
  const [totalPages, setTotalPages] = useState(0);
  const [user, setUser] = useState();

  const category = searchParams.get("category")
    ? searchParams.get("category")
    : props.category
    ? props.category
    : undefined;
  const model = searchParams.get("model");
  const country = searchParams.get("country");
  const city = searchParams.get("city");

  const first_registration = searchParams.get("first_registration");
  const color = searchParams.get("color");
  const price_from = searchParams.get("price_from");
  const price_to = searchParams.get("price_to");

  const milage_to = searchParams.get("milage_to");
  const milage_from = searchParams.get("milage_from");

  const kw_power_to = searchParams.get("kw_power_to");
  const kw_power_from = searchParams.get("kw_power_from");

  const horse_power_to = searchParams.get("horse_power_to");
  const horse_power_from = searchParams.get("horse_power_from");

  const euro_category = searchParams.get("euro_category");
  const fuel_type = searchParams.get("fuel_type");
  const gearbox = searchParams.get("gearbox");
  const axle_configuration = searchParams.get("axle_configuration");
  const total_axles = searchParams.get("total_axles");

  useEffect(() => {
    setTotalPages(Math.ceil(totalResults / 24));
  }, [totalResults]);

  useEffect(() => {
    if (!props.visible) {
      if (offers.length > 0) {
        if (width > 1568) {
          setVisibleOffers(offers.slice(0, 8));
        }
        if (width < 1568) {
          setVisibleOffers(offers.slice(0, 8));
        }
        if (width < 1280) {
          setVisibleOffers(offers.slice(0, 4));
        }
        if (width < 996) {
          setVisibleOffers(offers.slice(0, 6));
        }
        if (width < 568) {
          setVisibleOffers(offers.slice(0, 8));
        }
      }
    } else {
      setVisibleOffers(offers);
    }
  }, [width, offers]);

  useEffect(() => {
    const config = {};
    let link = `${process.env.REACT_APP_API_URL}/listings?limit=${props.limit}&page=${page}`;

    if (category) {
      link = link.concat(`&category=${category}`);
    }

    if (country) {
      link = link.concat(`&country=${country}`);
    }
    if (city) {
      link = link.concat(`&city=${city}`);
    }
    if (model) {
      link = link.concat(`&model=${model}`);
    }
    if (color) {
      link = link.concat(`&color=${color}`);
    }
    if (price_from) {
      link = link.concat(`&price_from=${price_from}`);
    }
    if (price_to) {
      link = link.concat(`&price_to=${price_to}`);
    }

    if (milage_from) {
      link = link.concat(`&milage_from=${milage_from}`);
    }
    if (milage_to) {
      link = link.concat(`&milage_to=${milage_to}`);
    }

    if (horse_power_from) {
      link = link.concat(`&horse_power_from=${horse_power_from}`);
    }
    if (horse_power_to) {
      link = link.concat(`&horse_power_to=${horse_power_to}`);
    }
    if (kw_power_from) {
      link = link.concat(`&kw_power_from=${kw_power_from}`);
    }
    if (kw_power_to) {
      link = link.concat(`&kw_power_to=${kw_power_to}`);
    }

    if (euro_category) {
      link = link.concat(`&euro_category=${euro_category}`);
    }
    if (fuel_type) {
      link = link.concat(`&fuel_type=${fuel_type}`);
    }

    if (gearbox) {
      link = link.concat(`&gearbox=${gearbox}`);
    }
    if (axle_configuration) {
      link = link.concat(`&axle_configuration=${axle_configuration}`);
    }
    if (total_axles) {
      link = link.concat(`&total_axles=${total_axles}`);
    }
    if (first_registration) {
      link = link.concat(`&first_registration=${first_registration}`);
    }

    if (props.user) {
      config["headers"] = {
        Authorization: `Bearer ${props.user}`,
      };
      setUser(true);
    }
    if (props.newOffers) {
      link = link.concat(`&sort=created`);
    }
    if (props.limit) {
      link = link.concat(`&limit=${props.limit}`);
    }
    setLoading(true)
    axios
      .get(link, config)
      .then((res) => {
        setOffers(res.data.listings);
        setTotalResults(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [
    page,
    category,
    model,
    country,
    city,
    first_registration,
    color,
    price_from,
    price_to,
    milage_from,
    milage_to,
    kw_power_from,
    kw_power_to,
    horse_power_from,
    horse_power_to,
    euro_category,
    fuel_type,
    gearbox,
    axle_configuration,
    total_axles,
  ]);

  return (
    <>
      <div id="offers"></div>
      <div className="offers-container flex content-start justify-center">
        {props?.sectionTitle && (
          <h2
            style={{
              width: "100%",
              fontFamily: "Inter",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "120%",
              marginBottom: "20px",
              marginRight: "16px",
            }}
          >
            {props.sectionTitle}
          </h2>
        )}
        {visibleOffers.length > 0 &&
          visibleOffers.map((offer) => (
            <OfferCard key={offer._id} offer={offer} user={props.user} />
          ))}
        {visibleOffers.length === 0 && user && (
          <div>
            <h2>You have not created any listings</h2>
            <div className="add-offer">
              <a href="/add-listing">Create Listing</a>
            </div>
          </div>
        )}

        {!loading && visibleOffers.length === 0 && !user && (
          <div style={{ textAlign: "center" }}>
            <svg
              style={{ margin: "0 auto 50px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="141"
              height="135"
              viewBox="0 0 141 135"
              fill="none"
            >
              <ellipse cx="70" cy="127.5" rx="60" ry="7.5" fill="#D9D9D9" />
              <circle cx="66" cy="51" r="44" stroke="#272A64" strokeWidth="8" />
              <path
                d="M134.172 124.829C135.734 126.391 138.266 126.391 139.828 124.829C141.391 123.267 141.391 120.734 139.828 119.172L134.172 124.829ZM93.1716 83.8287L134.172 124.829L139.828 119.172L98.8284 78.1718L93.1716 83.8287Z"
                fill="#272A64"
              />
              <path
                d="M54.5 37.9146L76.5 59.9146"
                stroke="#272A64"
                strokeWidth="6"
                strokeLinecap="round"
              />
              <path
                d="M54.5 59.9143L76.5 37.9143"
                stroke="#272A64"
                strokeWidth="6"
                strokeLinecap="round"
              />
              <circle cx="3" cy="3" r="3" fill="#D9D9D9" />
              <circle cx="36" cy="105" r="3" fill="#D9D9D9" />
              <circle cx="124.5" cy="38.5" r="3.5" fill="#D9D9D9" />
            </svg>
            <h3
              style={{
                color: "#000",
                fontFamily: "Inter",
                fontSize: "24px",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "120%",
                marginBottom: "30px",
              }}
            >
              We're sorry, but there are no results matching your current search
              criteria.
            </h3>
            <p>
              Consider broadening your filters or try a different search term to
              discover more options. <br></br>If you need further assistance,
              feel free to contact our support team.
            </p>
          </div>
        )}

        {props.visible && (
          <div className="paginate">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              onPageChange={() => {
                setPage(page + 1);
              }}
              pageRangeDisplayed={5}
              pageCount={totalPages}
              previousLabel="< "
              renderOnZeroPageCount={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default OffersContainer;
