import Location from "../../assets/icons/location.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import DefaultImage from "../../assets/default.png";
import countries from "../../data/countries.json";

const OfferCard = (props) => {
  const [country, setCountry] = useState();
  const offer = props.offer;
  useEffect(() => {
    const country = offer.country;
    if (country && country.length > 2) {
      setCountry(country);
    } else {
      const index = countries.findIndex((el) => el.value === country);
      if (index > -1) {
        setCountry(countries[index].label);
      }
    }
  }, [props.offer]);
  function numberWithComma(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  }
  function numberWithSpace(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return x;
  }
  return (
    <div className="offer-card">
      <Link to={`/listing/${offer._id}`}>
        <div className="image-wrapper">
          {offer.url_list && Object.entries(offer.url_list).length > 0 ? (
            <img src={Object.entries(offer.url_list)[0][1]} />
          ) : (
            <img src={DefaultImage} />
          )}
        </div>
        <div className="offer-content">
          <div className="details">
            <h3>
              {offer.brand} {offer.model}
            </h3>
            {props.user &&
              offer._creator &&
              offer._creator === props.user &&
              offer.valid_until && (
                <div className="detail">
                  <span>Expires:</span>

                  {Date.parse(offer.valid_until) < Date.parse(new Date()) ? (
                    <span className="value" style={{ color: "red" }}>
                      Expired
                    </span>
                  ) : (
                    <span className="value">
                      {offer?.valid_until.split("T")[0]}
                    </span>
                  )}
                </div>
              )}
            {offer.external_id && (
              <div className="detail">
                <span>ID:</span>
                <span className="value">{offer.external_id}</span>
              </div>
            )}
            {offer.first_registration && (
              <div className="detail">
                <span>Registration date:</span>
                <span className="value">{offer.first_registration}</span>
              </div>
            )}
            {offer.mileage && (
              <div className="detail">
                <span>Milage:</span>
                <span className="value">
                  {numberWithComma(offer.mileage)} km
                </span>
              </div>
            )}
            {offer.euro_category && (
              <div className="detail">
                <span>EURO:</span>
                <span className="value">{offer.euro_category}</span>
              </div>
            )}
            {offer.horse_power && (
              <div className="detail">
                <span>Power HP:</span>
                <span className="value">{offer.horse_power}</span>
              </div>
            )}
          </div>
          <div className="price-area">
            <div className="price">{numberWithSpace(offer.price)} €</div>
            <div className="country-details">
              <div>
                <img src={Location} />
              </div>
              <div>
                <img
                  style={{ border: "solid 1px", borderRadius: "50%" }}
                  src={`/flags/${country?.toLowerCase()}.svg`}
                />
              </div>
            </div>
          </div>
          <Link style={{ width: "100%" }} to={`/listing/${offer._id}`}>
            <button
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              View Offer
            </button>
          </Link>
          {props.user && offer._creator && offer._creator === props.user && (
            <Link
              style={{ width: "100%", marginTop: "1rem" }}
              to={`/add-listing?id=${offer._id}`}
            >
              <button
                className="bg-yellow-400 hover:bg-yellow-600 text-white py-2 px-4 rounded"
                onClick={() => {}}
              >
                Edit Offer
              </button>
            </Link>
          )}

          {props.user && offer._creator && offer._creator === props.user && (
            <Link
              style={{ width: "100%", marginTop: "1rem" }}
              to={`/payment?id=${offer._id}`}
            >
              <button
                className="bg-yellow-600 hover:bg-yellow-400 text-white py-2 px-4 rounded"
                onClick={() => {}}
              >Extend period
                
              </button>
            </Link>
          )}
        </div>
      </Link>
    </div>
  );
};

export default OfferCard;
